import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { Observable, pipe, throwError } from 'rxjs';
import { Router, RouterModule } from '@angular/router';
//import { _throw } from 'rxjs/observable/throw';
import { catchError } from 'rxjs/operators';
//import{ErrorObservable} from 'rxjs/observable/ErrorObservable';
let headers = new HttpHeaders().set('content-type', 'application/x-www-form-urlencoded;charset=UTF-8');

import { environment } from '../environments/environment';
import { ToasterService } from './toaster.service';
@Injectable({
  providedIn: 'root'
})

export class DataService {


  public isUserLogedin: boolean = false;

  public client_name: string = "";
  public client_img: string = "";
  public isload: boolean = false;
  public requestSeo: boolean = false;
  public requestSm: boolean = false;
  public requestMypr: boolean = false;
  public requestWeb: boolean = false;
  public requestmypl: boolean = false;
  public requestPt: boolean = false;

  /* For Navigation */
  public is_dashboard: number = 0;
  public is_seo: number = 0;
  public is_newseo: number = 0;
  public is_newseos: number = 0;
  public is_sm: number = 0;
  public is_newsm: number = 0; //new social media
  public is_webadmin: number = 0;
  public is_targeting: number = 0;
  public is_mypr: number = 0;
  public is_mypl: number = 0;
  public is_pbform: number = 0;
  public is_cticket: number = 0;
  public is_lticket: number = 0;
  public is_am: number = 0;
  public mypr_access_key: string = "";
  public targeting_access_key: string = "";
  public web_admin_name: string = "";
  public website: string = "";
  public admin_key: string = "";
  public targeting_key: string = "";
  public rmurl: string = "";
  public adminurl: string = "";
  public targetingurl: string = "";
  private username;
  private admin;
  public admin_visit: number = 0;
  public rm_visit: number = 0;
  public seo_tab: number = 0;
  public app_url: string = "https://pbostagingnew.practicebuilders.com/client-portal/";

  private datey: string = "";
  //page:string="login";
  constructor(private http: HttpClient, private router: Router, private toastr: ToasterService) {
    this.admin = true;

    if (sessionStorage.getItem("isLogedin") == "true") {
      this.isUserLogedin = true;


      this.client_name = sessionStorage.getItem("firstName") + " " + sessionStorage.getItem("lastName");
      //this.client_name="sandeep";
      this.client_img = sessionStorage.getItem("client_profile_image");
      this.adminurl = sessionStorage.getItem("website") + "/pbvault_signin/verify.php/?key=" + sessionStorage.getItem('admin_key') + "&path=" + sessionStorage.getItem("web_admin_name") + "&tk=" + (Math.floor(Math.random() * 90000) + 10000);
      this.rmurl = "https://expertpracticemarketing.com/myprac1/public/api/pb-integration/get/param/safari-auth/access_key/" + sessionStorage.getItem("mypr_access_key");
      this.targetingurl = "https://adsmanager.practicebuilders.com/access_key/" + sessionStorage.getItem("targeting_access_key");
      
    }
    else {
      this.isUserLogedin = false;
    }
    var d = new Date();


    var etwoDigitMonth = ((d.getMonth() + 1) >= 10) ? (d.getMonth() + 1) : '0' + (d.getMonth() + 1);
    var etwoDigitDate = ((d.getDate()) >= 10) ? (d.getDate()) : '0' + (d.getDate());

    //var d = new Date();
    this.datey = d.getFullYear() + "-" + etwoDigitMonth + "-" + etwoDigitDate;
    //var sdate=date.getFullYear()+"-"+stwoDigitMonth+"-"+stwoDigitDate;



  }

  getdata(client_id, auth_token) {
    // if(sessionStorage.getItem("is_web")!="1")
    //   {
    //    return this.http.get("assets/ticket.json",{responseType: 'json'})
    //   .pipe(catchError(this.errorHandling)) 
    //   }
    // else
    // {
    //console.log('hh');
    return this.http.get(environment.baseUrl + "jira/get_issues?client_id=" + client_id + "&auth_token=" + auth_token)
      .pipe(catchError(this.errorHandling))
    // }
  }

  get_blog() {
    return this.http.get("https://geotargetus.com/blog_rest_api.php")
      .pipe(catchError(this.errorHandling))
  }

  /*
  postdata(data)
  {
  return this.http.post("https://admin.myormanager.com/api/addj.php",data,{responseType: 'text'})
  .pipe(catchError(this.errorHandling))
  } */

  postdata(data) {

    //  if(str!="no" || str=="")
    //  { 
    //  data.img=str; 
    //  }
    //  else
    //  {
    //   data.img=undefined; 
    //  }
    console.log(data);
    return this.http.post(environment.baseUrl + "jira/create_issue", data)
      .pipe(catchError(this.errorHandling))
  }

  addAttachment(data) {
    return this.http.post(environment.baseUrlApi + "api/add_attachment.php", data, { responseType: 'text' })
      .pipe(catchError(this.errorHandling));
  }

  updateStatus(key, auth, id) {
    return this.http.get(environment.baseUrl + "jira/update_status?key=" + key + "&auth_token=" + auth + "&client_id=" + id, { responseType: 'json' })
      .pipe(catchError(this.errorHandling));
  }
  getFacebookLikes(profileId , accessKey) {
    return this.http.get(environment.baseUrl + "sm/fetch_fb_profile?mypr_id=" + profileId + "&mypr_access_key=" + accessKey , { responseType: 'json' })
      .pipe(catchError(this.errorHandling));
  }
  
  send_am_msg(datas) {
    return this.http.post(environment.baseUrlApi + "api/contact_am_gt.php", datas, { responseType: 'json' })
      .pipe(catchError(this.errorHandling))
  }
  uploaddata(data) {
    return this.http.post(environment.baseUrlApi + "api/uploadsj.php", data, { responseType: 'text' })
      .pipe(catchError(this.errorHandling))
  }

  getuser(username, password) {
    //return this.isUserLogedin=true;
    /* if(username=="admin" && password=="123")
     {
     //this.page="";
     sessionStorage.setItem("isLogedin", "true");
     return this.isUserLogedin=other_ullistingga_view_idtrue;
     }*/
    const body = new HttpParams()
      .set('email', username)
      .set('pwd', password)
      .set("device_id", "1")
      .set("device_type", "web");
    //var payload= {"email":username,"pwd":password,"device_id":"1222222342424","device_type":"android"};
    return this.http.post(environment.baseUrl + "clients/login", body.toString(), { headers: headers })
      .pipe(catchError(this.errorHandling))
  }

  change_password(oldpassword, newpassword, newpassword1) {
    const body = new HttpParams()
      .set('client_id', sessionStorage.getItem("client_id"))
      .set('auth_token', sessionStorage.getItem("auth_token"))
      .set("current_password", oldpassword)
      .set("new_password", newpassword)
      .set("confirm_password", newpassword1);
    //var payload= {"email":username,"pwd":password,"device_id":"1222222342424","device_type":"android"};
    return this.http.post(environment.baseUrl + "clients/changepassword", body.toString(), { headers: headers })
  }

  change_username(username) {
    const body = new HttpParams()
      .set('client_id', sessionStorage.getItem("client_id"))
      .set('auth_token', sessionStorage.getItem("auth_token"))
      .set('new_username', username);

    return this.http.post(environment.baseUrl + "clients/changeusername", body.toString(), { headers: headers })
  }

  g_analytic(sdate, edate, isfilter) {
    if(isfilter == 0){
      sdate="2023-01-01";
      edate="2023-06-30";
    }else{
      var Tsdate="2023-06-01";
      let d1 = new Date(Tsdate).getTime();
      let d2 = new Date(sdate).getTime();
      if(d1 < d2){
        sdate="2023-01-01";
      }
    }
    isfilter  =1;
    var client_id = sessionStorage.getItem("client_id");
    const body = new HttpParams()
      .set('view_id', sessionStorage.getItem("ga_view_id"))
      .set('start_date', sdate)
      .set('isfilter', isfilter)
      .set("end_date", edate)
      .set('auth_token', sessionStorage.getItem("auth_token"))
      .set('client_id', sessionStorage.getItem("client_id"))
      .set('seo_state', sessionStorage.getItem("seo_state"));

    if (sessionStorage.getItem("is_seo") != "1" || sessionStorage.getItem("is_newseo") != "1") {
      // return this.http.get("assets/seo-overview.json").pipe(catchError(this.errorHandling))
      return this.http.post(environment.baseUrl + "newseo/report", body.toString(), { headers: headers })
        .pipe(catchError(this.errorHandling))
    }
    else {
      //var payload= {"start_date":sdate,"end_date":edate,"client_id":client_id};
      return this.http.post(environment.baseUrl + "newseo/report", body.toString(), { headers: headers })
        .pipe(catchError(this.errorHandling))
    }


  }


  g_analytic_new(sdate, edate, isfilter) {
    //sdate="2019-01-01";
    //edate="2019-01-31";
    var client_id = sessionStorage.getItem("client_id");
    let property_id = sessionStorage.getItem("property_id");
    const body = new HttpParams()
      .set('view_id', sessionStorage.getItem("ga_view_id"))
      .set('start_date', sdate)
      .set('isfilter', isfilter)
      .set("end_date", edate)
      .set("property_id", property_id)
      .set('auth_token', sessionStorage.getItem("auth_token"))
      .set('client_id', sessionStorage.getItem("client_id"))
      .set('seo_state', sessionStorage.getItem("seo_state"));

    if (sessionStorage.getItem("is_seo") != "1" || sessionStorage.getItem("is_newseo") != "1") {
      return this.http.get("assets/seo-overview.json")
        .pipe(catchError(this.errorHandling))
    }else if(isfilter){
      //return this.http.post(environment.baseUrlForGa, body.toString(), { headers: headers }).pipe(catchError(this.errorHandling))
      return this.http.post(environment.baseUrlApi + "api/cron/googleanyalitic/filter_report.php", body.toString(), { headers: headers }).pipe(catchError(this.errorHandling))
    }
    else {
      //var payload= {"start_date":sdate,"end_date":edate,"client_id":client_id};
     // return this.http.post(environment.baseUrlForGa, body.toString(), { headers: headers }).pipe(catchError(this.errorHandling))

      //return this.http.post(environment.baseUrl + "newseo/gaReport", body.toString(), { headers: headers }).pipe(catchError(this.errorHandling))

      return this.http.post(environment.baseUrlApi + "api/cron/googleanyalitic/filter_report_yearly.php", body.toString(), { headers: headers }).pipe(catchError(this.errorHandling))
    }


  }


  gmb(sdate, edate) {
    //console.log(sdate);
    //console.log(edate);
    var emonth = edate.substr(5, 2);
    var eyear = edate.substr(0, 4);
    //console.log(eyear+"-"+emonth);
    var date = new Date();
    var stwoDigitMonth = ((date.getMonth() + 1) >= 10) ? (date.getMonth() + 1) : '0' + (date.getMonth() + 1);
    if (date.getFullYear() == eyear && stwoDigitMonth == emonth) {
      edate = date.getFullYear() + "-" + stwoDigitMonth + "-" + date.getDate();
    }

    var client_id = sessionStorage.getItem("client_id");
    var gmb_location = sessionStorage.getItem("gmb");
    var datas = { "client_id": client_id, "start_date": sdate, "end_date": edate, "gmb_location": gmb_location };
    if (sessionStorage.getItem("is_seo") != "1" || sessionStorage.getItem("is_newseo") != "1") {
      return this.http.get("assets/gmb.json")
        .pipe(catchError(this.errorHandling))
    }
    else {
      return this.http.post(environment.baseUrlApi + "api/gmb_datar.php", datas, { responseType: 'json' })
        .pipe(catchError(this.errorHandling))
    }
  }

  webmaster(sdate, edate) {

    var emonth = edate.substr(5, 2);
    var eyear = edate.substr(0, 4);
    //console.log(eyear+"-"+emonth);
    var date = new Date();
    var stwoDigitMonth = ((date.getMonth() + 1) >= 10) ? (date.getMonth() + 1) : '0' + (date.getMonth() + 1);
    if (date.getFullYear() == eyear && stwoDigitMonth == emonth) {
      edate = date.getFullYear() + "-" + stwoDigitMonth + "-" + date.getDate();
    }

    var client_id = sessionStorage.getItem("client_id");
    var website = sessionStorage.getItem("website");
    var datas = { "client_id": client_id, "start_date": sdate, "end_date": edate, "website": website };
    if (sessionStorage.getItem("is_seo") != "1" || sessionStorage.getItem("is_newseo") != "1") {
      return this.http.get("assets/webmaster.json")
        .pipe(catchError(this.errorHandling))
    }
    else {
      return this.http.post(environment.baseUrlApi + "api/webmaster_data.php", datas, { responseType: 'json' })
        .pipe(catchError(this.errorHandling))
    }
  }

  semrush(display_date) {


    const body = new HttpParams()
      .set('domain', sessionStorage.getItem("website"))
      .set("display_date", display_date)
      .set('auth_token', sessionStorage.getItem("auth_token"))
      .set('client_id', sessionStorage.getItem("client_id"));

    if (sessionStorage.getItem("is_seo") != "1" || sessionStorage.getItem("is_newseo") != "1") {
      return this.http.get("assets/seo-semrush.json")
        .pipe(catchError(this.errorHandling))
    }
    else {    //var payload= {"email":username,"pwd":password,"device_id":"1222222342424","device_type":"android"};
      return this.http.post(environment.baseUrl + "seo/newsemrush", body.toString(), { headers: headers })
        .pipe(catchError(this.errorHandling))
    }

  }
  saveroi(avgspend, rate) {
    const body = new HttpParams()
      .set('average_patient_spend', avgspend)
      .set('lead_conversion_rate', rate)
      .set("client_id", sessionStorage.getItem("client_id"))
      .set("auth_token", sessionStorage.getItem("auth_token"));
    //var payload= {"email":username,"pwd":password,"device_id":"1222222342424","device_type":"android"};
    return this.http.post(environment.baseUrl + "clients/update_roi_caculator", body.toString(), { headers: headers })
      .pipe(catchError(this.errorHandling))
  }
  seo_traffic(sdate, edate) {

    const body = new HttpParams()
      .set('view_id', sessionStorage.getItem("ga_view_id"))
      .set('start_date', sdate)
      .set("end_date", edate)
      .set('auth_token', sessionStorage.getItem("auth_token"))
      .set('client_id', sessionStorage.getItem("client_id"));

    if (sessionStorage.getItem("is_seo") != "1" || sessionStorage.getItem("is_newseo") != "1") {
      return this.http.get("assets/seo-traffic.json")
        .pipe(catchError(this.errorHandling))
    }
    else {
      //var payload= {"email":username,"pwd":password,"device_id":"1222222342424","device_type":"android"};
      return this.http.post(environment.baseUrl + "seo/traffic", body.toString(), { headers: headers })
        .pipe(catchError(this.errorHandling))
    }

  }
  mypl() {

    var client_id = sessionStorage.getItem("client_id");
    const body = new HttpParams()

      .set('auth_token', sessionStorage.getItem("auth_token"))
      .set('client_id', sessionStorage.getItem("client_id"))
    if (sessionStorage.getItem("mypl") != "1") {
      return this.http.get("assets/mypl.json")
        .pipe(catchError(this.errorHandling))
    }
    else {
      return this.http.post(environment.baseUrl + "seo/mypl_data", body.toString(), { headers: headers })
        .pipe(catchError(this.errorHandling))
    }
  }

  pbformFormlist() {

    var client_id = sessionStorage.getItem("client_id");
    //const body = new HttpParams()
    var data;
    data = { "pb_client_id": sessionStorage.getItem("pb_client_id"), "client_id": client_id, "auth_token": sessionStorage.getItem("auth_token") }
    //.set('auth_token', sessionStorage.getItem("auth_token"))
    //.set('client_id', sessionStorage.getItem("client_id"))

    //return this.http.post(environment.baseUrl+"seo/mypl_data",body.toString(),{headers:headers})
    //.pipe(catchError(this.errorHandling))
    return this.http.post(environment.baseUrl + "pbf/forms", data, { responseType: 'json' })
      .pipe(catchError(this.errorHandling))
  }

  pbformSubmissionlist(form_id, en_string) {

    var client_id = sessionStorage.getItem("client_id");
    var data;
    data = {
      "pb_client_id": sessionStorage.getItem("pb_client_id"), "client_id": client_id, "auth_token": sessionStorage.getItem("auth_token"), "form_id": form_id,
      "en_string": en_string
    }

    return this.http.post(environment.baseUrl + "pbf/submissionlist", data, { responseType: 'json' })
      .pipe(catchError(this.errorHandling))

  }


  pbformFormdetail(form_id, en_string, id) {

    var client_id = sessionStorage.getItem("client_id");
    var data;
    data = {
      "pb_client_id": sessionStorage.getItem("pb_client_id"), "client_id": client_id, "auth_token": sessionStorage.getItem("auth_token"), "form_id": form_id,
      "en_string": en_string, "id": id
    }

    return this.http.post(environment.baseUrl + "pbf/detail", data, { responseType: 'json' })
      .pipe(catchError(this.errorHandling))
  }

  twitter() {


    const body = new HttpParams()

      .set("twitter_id", sessionStorage.getItem("twitter_id"))
      .set('auth_token', sessionStorage.getItem("auth_token"))
      .set('client_id', sessionStorage.getItem("client_id"));

    if (sessionStorage.getItem("is_sm") != "1" || sessionStorage.getItem("is_newsm") != "1" ) { // new social media 
      return this.http.get("assets/twitter.json")
        .pipe(catchError(this.errorHandling))
    }
    else {
      //var payload= {"email":username,"pwd":password,"device_id":"1222222342424","device_type":"android"};
      return this.http.post(environment.baseUrl + "sm/twitter", body.toString(), { headers: headers })
        .pipe(catchError(this.errorHandling))
    }
  }

  linkedin() {


    const body = new HttpParams()

      .set("likedin_id", sessionStorage.getItem("linkedIn_id"))
      .set('auth_token', sessionStorage.getItem("auth_token"))
      .set('client_id', sessionStorage.getItem("client_id"));

    if (sessionStorage.getItem("is_sm") != "1" || sessionStorage.getItem("is_newsm") != "1"  ) { // new social media 
      return this.http.get("assets/linkedin.json")
        .pipe(catchError(this.errorHandling))
    }
    else {
      //var payload= {"email":username,"pwd":password,"device_id":"1222222342424","device_type":"android"};
      return this.http.post(environment.baseUrl + "sm/linkedin", body.toString(), { headers: headers })
        .pipe(catchError(this.errorHandling))
    }

  }

  f_header() {
    var data = {
      "fb_page_id": sessionStorage.getItem("fb_page_id"),
      'current_date': this.datey,
      'client_id': sessionStorage.getItem("client_id")
    };

    //var payload= {"email":username,"pwd":password,"device_id":"1222222342424","device_type":"android"};
    if (sessionStorage.getItem("is_sm") != "1" || sessionStorage.getItem("is_newsm") != "1" ) {  // new social media 
      return this.http.get("assets/facebook-header.json")
        .pipe(catchError(this.errorHandling))
    }
    else {
      return this.http.post(environment.baseUrlApi + "api/facebook_header.php", data)
        .pipe(catchError(this.errorHandling))
    }
  }

  f_graph() {
    var data = {
      "fb_page_id": sessionStorage.getItem("fb_page_id"),
      'current_date': this.datey,
      'client_id': sessionStorage.getItem("client_id")
    };

    if (sessionStorage.getItem("is_sm") != "1"|| sessionStorage.getItem("is_newsm") != "1" ) {  // new social media 
      return this.http.get("assets/facebook-graph.json")
        .pipe(catchError(this.errorHandling))
    }
    else {
      //var payload= {"email":username,"pwd":password,"device_id":"1222222342424","device_type":"android"};
      return this.http.post(environment.baseUrlApi + "api/facebook_graph.php", data)
        .pipe(catchError(this.errorHandling))
    }

  }

  rm_dashboard_acc_list() {
    //var payload= {"email":username,"pwd":password,"device_id":"1222222342424","device_type":"android"};
    return this.http.get("https://expertpracticemarketing.com/myprac1/public/api/doctor/get?param=choose&&userId=" + sessionStorage.getItem("profile_id"))
      .pipe(catchError(this.errorHandling))

  }

  rm_dashboard(acc_id) {
    var ac_id;
    if (acc_id != "Select Account") {
      ac_id = acc_id;
    }
    else {
      ac_id = sessionStorage.getItem("profile_id");
    }
    if (sessionStorage.getItem("is_rm") != "1") {
      return this.http.get("assets/dash-site-review.json")
        .pipe(catchError(this.errorHandling))
    }
    else {
      //var payload= {"email":username,"pwd":password,"device_id":"1222222342424","device_type":"android"};
      return this.http.get("https://expertpracticemarketing.com/myprac1/public/api/dashboard/get?param=user&userId=" + ac_id)
        .pipe(catchError(this.errorHandling))
    }

  }
  rm_request_am(param) {
    var type;
    if (param.textt == true && param.emailt == true) {
      type = "both";
    }
    else if (param.textt == true) {
      type = "sms";
    }
    else if (param.emailt == true) {
      type = "email";
    }
    else {
      type = "";
    }
    var userId = param.doc;
    var firstName = param.cfn;
    var lastName = param.cln;
    var email = param.ce;
    var phone = param.cp;

    return this.http.get("https://expertpracticemarketing.com/myprac1/public/api/list/put?param=request&userId=" + userId + "&type=" + type + "&firstName=" + firstName + "&lastName=" + lastName + "&phone=" + phone + "&email=" + email)
      .pipe(catchError(this.errorHandling))
  }
  rm_report_capture(startdate, enddate, profileId, status, provider, offset, review_source) {
    const body = new HttpParams()

      .set("profileID", profileId)
      //status=all&startdate=&enddate=&keyword=&offset=0&limit=10&
      .set('status', status)
      .set('startdate', startdate)
      .set('enddate', enddate)

      .set('provider', provider)
      .set('review_source', review_source)
      .set('keyword', "")
      .set('offset', offset)
      .set('limit', "10");

    if (sessionStorage.getItem("is_rm") != "1") {
      return this.http.get("assets/capture.json")
        .pipe(catchError(this.errorHandling))
    }
    else {
      //var payload= {"email":username,"pwd":password,"device_id":"1222222342424","device_type":"android"};
      return this.http.post("https://expertpracticemarketing.com/myprac1/public/api/reports/put/param/captured_report", body.toString(), { headers: headers })
        .pipe(catchError(this.errorHandling))
    }
  }
  rm_report_publish(startdate, enddate, profileId, review_rating, status, provider, offset, review_score) {
    const body = new HttpParams()

      .set("profileID", profileId)
      //status=all&startdate=&enddate=&keyword=&offset=0&limit=10&
      .set('status', status)
      .set('startdate', startdate)
      .set('enddate', enddate)
      .set('review_rating', review_rating)
      .set('provider', provider)
      .set('review_score', review_score)
      .set('keyword', "")
      .set('offset', offset)
      .set('limit', "10");

    if (sessionStorage.getItem("is_rm") != "1") {
      return this.http.get("assets/publish.json", { responseType: 'json' })
        .pipe(catchError(this.errorHandling))
    }
    else {
      //var payload= {"email":username,"pwd":password,"device_id":"1222222342424","device_type":"android"};
      return this.http.post("https://expertpracticemarketing.com/myprac1/public/api/reports/put/param/published_report", body.toString(), { headers: headers })
        .pipe(catchError(this.errorHandling))
    }

  }

  public rm_report_publish_graph(sdate, edate, profileId, rating, status, provider_id, review_source) {
    const body = new HttpParams()

      .set("profileID", profileId)
      //status=all&startdate=&enddate=&keyword=&offset=0&limit=10&
      .set('status', status)
      .set('startdate', sdate)
      .set('enddate', edate)
      .set('review_rating', rating)
      .set('provider', provider_id)
      .set('review_score', review_source)
      .set('keyword', "");

    if (sessionStorage.getItem("is_rm") != "1") {
      return this.http.get("assets/publish.json", { responseType: 'json' })
        .pipe(catchError(this.errorHandling))
    }
    else {
      //var payload= {"email":username,"pwd":password,"device_id":"1222222342424","device_type":"android"};
      return this.http.post("https://expertpracticemarketing.com/myprac1/public/api/reports/put/param/published_report_graph", body.toString(), { headers: headers })
        .pipe(catchError(this.errorHandling))
    }
  }
  rm_review_delete(type, id) {
    var profileId = sessionStorage.getItem("profile_id");
    const body = new HttpParams()

      .set("activeLoginID", profileId)
      //status=all&startdate=&enddate=&keyword=&offset=0&limit=10&
      .set('review_type', type)
      .set('id', id)
    //var payload= {"email":username,"pwd":password,"device_id":"1222222342424","device_type":"android"};
    return this.http.post("https://expertpracticemarketing.com/myprac1/public/api/reports/delete", body.toString(), { headers: headers })
      .pipe(catchError(this.errorHandling))

  }
  rm_report_feed(value, review_type, id, profileID) {
    const body = new HttpParams()

      .set("profileID", profileID)
      //status=all&startdate=&enddate=&keyword=&offset=0&limit=10&
      .set('value', value)

      .set('review_type', review_type)
      .set('id', id)


    //var payload= {"email":username,"pwd":password,"device_id":"1222222342424","device_type":"android"};
    return this.http.post("https://expertpracticemarketing.com/myprac1/public/api/reports/put/param/update_published_feed", body.toString(), { headers: headers })
      .pipe(catchError(this.errorHandling))


  }
  rm_campaign(startdate, enddate, offset, status_email) {



    const body = new HttpParams()

      .set("profileID", sessionStorage.getItem("profile_id"))
      .set('status', status_email)
      .set('startdate', startdate)
      .set('enddate', enddate)
      .set('keyword', "")
      .set('offset', offset)
      .set('limit', "10");

    if (sessionStorage.getItem("is_rm") != "1") {
      return this.http.get("assets/campaign.json")
        .pipe(catchError(this.errorHandling))
    }
    else {
      //var payload= {"email":username,"pwd":password,"device_id":"1222222342424","device_type":"android"};
      return this.http.post("https://expertpracticemarketing.com/myprac1/public/api/compaign/get", body.toString(), { headers: headers })
        .pipe(catchError(this.errorHandling))
    }
  }
  rm_campaign_total(startdate, enddate, status_email) {
    const body = new HttpParams()

      .set("profileID", sessionStorage.getItem("profile_id"))
      .set('status', status_email)
      .set('startdate', startdate)
      .set('enddate', enddate)
      .set('keyword', "")

    //var payload= {"email":username,"pwd":password,"device_id":"1222222342424","device_type":"android"};
    return this.http.post("https://expertpracticemarketing.com/myprac1/public/api/compaign/index", body.toString(), { headers: headers })
      .pipe(catchError(this.errorHandling))

  }
  rm_campaign_resend(type, id) {



    const body = new HttpParams()

      .set("profileID", sessionStorage.getItem("profile_id"))

      .set('compaign_type', type)
      .set('id', id);

    //var payload= {"email":username,"pwd":password,"device_id":"1222222342424","device_type":"android"};
    return this.http.post("https://expertpracticemarketing.com/myprac1/public/api/compaign/put/param/resend", body.toString(), { headers: headers })
      .pipe(catchError(this.errorHandling))

  }

  rm_campaign_delete(type, id) {
    const body = new HttpParams()
      .set('compaign_type', type)
      .set('id', id);

    //var payload= {"email":username,"pwd":password,"device_id":"1222222342424","device_type":"android"};
    return this.http.post("https://expertpracticemarketing.com/myprac1/public/api/compaign/delete", body.toString(), { headers: headers })
      .pipe(catchError(this.errorHandling))

  }


  dashboard_sm_seo(sdate, edate): Observable<any> {
    console.log(sdate);
    console.log(edate);
    const body = new HttpParams()
      .set('view_id', sessionStorage.getItem("ga_view_id"))
      .set('start_date', sdate)
      .set("end_date", edate)
      .set("likedin_id", sessionStorage.getItem("linkedIn_id"))
      .set("twitter_id", sessionStorage.getItem("twitter_id"))
      .set('auth_token', sessionStorage.getItem("auth_token"))
      .set('client_id', sessionStorage.getItem("client_id"));

    if (sessionStorage.getItem("is_seo") != "1" || sessionStorage.getItem("is_newseo") != "1") {
      return this.http.get("assets/dashboard.json")
        .pipe(catchError(this.errorHandling))
    }
    //var payload= {"email":username,"pwd":password,"device_id":"1222222342424","device_type":"android"};
    else {
      return this.http.post(environment.baseUrl + "clients/dashboard", body.toString(), { headers: headers })
        .pipe(catchError(this.errorHandling))
    }


  }

  dashboard_sm_seo_new(sdate, edate): Observable<any> {
    //var client_id = sessionStorage.getItem("client_id");
   // let property_id = sessionStorage.getItem("property_id");
    const body = new HttpParams()
    .set('view_id', sessionStorage.getItem("ga_view_id"))
    .set('start_date', sdate)
    .set("end_date", edate)
    .set("likedin_id", sessionStorage.getItem("linkedIn_id"))
    .set("twitter_id", sessionStorage.getItem("twitter_id"))
    .set('auth_token', sessionStorage.getItem("auth_token"))
    .set('client_id', sessionStorage.getItem("client_id"));

    if (sessionStorage.getItem("is_seo") != "1" || sessionStorage.getItem("is_newseo") != "1") {
      return this.http.get("assets/dashboard.json")
        .pipe(catchError(this.errorHandling))
    }
    //var payload= {"email":username,"pwd":password,"device_id":"1222222342424","device_type":"android"};
    else {
      return this.http.post(environment.baseUrlApi + "api/cron/googleanyalitic/filter_report_overview.php", body.toString(), { headers: headers }).pipe(catchError(this.errorHandling))
    }


  }


  dashboard_sm(sdate, edate): Observable<any> {
    const body = new HttpParams()
      .set('view_id', sessionStorage.getItem("ga_view_id"))
      .set('start_date', sdate)
      .set("end_date", edate)
      .set("likedin_id", sessionStorage.getItem("linkedIn_id"))
      .set("twitter_id", sessionStorage.getItem("twitter_id"))
      .set('auth_token', sessionStorage.getItem("auth_token"))
      .set('client_id', sessionStorage.getItem("client_id"));

    if (sessionStorage.getItem("is_sm") != "1"|| sessionStorage.getItem("is_newsm") != "1" ) {  // new social media 
      return this.http.get("assets/dashboard.json")
        .pipe(catchError(this.errorHandling))
    }
    //var payload= {"email":username,"pwd":password,"device_id":"1222222342424","device_type":"android"};
    else {
      return this.http.post(environment.baseUrl + "clients/dashboard", body.toString(), { headers: headers })
        .pipe(catchError(this.errorHandling))
    }


  }

  dashboard_rm(acc_id) {
    var ac_id;
    if (acc_id != "Select Account") {
      ac_id = acc_id;
    }
    else {
      ac_id = sessionStorage.getItem("profile_id");
    }

    if (sessionStorage.getItem("is_rm") != "1") {
      return this.http.get("assets/dash-rm-overview.json")
        .pipe(catchError(this.errorHandling))
    }
    else {
      //var payload= {"email":username,"pwd":password,"device_id":"1222222342424","device_type":"android"};
      return this.http.get("https://expertpracticemarketing.com/myprac1/public/api/dashboard/get?param=overview&&userId=" + ac_id)
        .pipe(catchError(this.errorHandling))
    }

  }
  checklogin() {
    return this.isUserLogedin;
  }

  check_auth_exp() {

    const body = new HttpParams()
      .set('auth_token', sessionStorage.getItem("auth_token"))
      .set('client_id', sessionStorage.getItem("client_id"));


    this.http.post(environment.baseUrl + "clients/auth", body.toString(), { headers: headers })
      .subscribe((data) => {
        if (data['status'] == 401) {
          this.logout_auth();
        }
      })
    //.pipe(catchError(this.errorHandling))


  }

  getadmin_client() {
    return this.http.get(environment.baseUrlApi + "api/client_list.php");
  }

  getadmin_issue() {
    return this.http.get("https://localhost/aproject-api/fetchj.php");
  }

  format_big(num) {

    if (num >= 1000000000) {
      return (num / 1000000000).toFixed(1).replace(/\.0$/, '') + 'G';
    }
    if (num >= 1000000) {
      return (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
    }
    if (num >= 1000) {
      return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
    }
    return num;

  }
  format_date_picker(n) {
    var u_format_split_date = n.split("/");
    return u_format_split_date[2] + "-" + u_format_split_date[0] + "-" + u_format_split_date[1];
  }
  find_current_date() {
    var today = new Date();
    return today.getDate();
  }
  find_month_last_date(n, y) {
    var today = new Date();
    var mnth = today.getMonth() + 1;
    var years = today.getFullYear();

    /*if(mnth==n && years==y)
    {
      var today = new Date();
      return today.getDate();
    }
    else
    {*/
    if (n == "01") {
      return "31";
    }

    else if (n == "02") {
      return "28";
    }
    else if (n == "03") {
      return "31";
    }

    else if (n == "04") {
      return "30";
    }

    else if (n == "05") {
      return "31";
    }

    else if (n == "06") {
      return "30";
    }

    else if (n == "07") {
      return "31";
    }

    else if (n == "08") {
      return "31";
    }

    else if (n == "09") {
      return "30";
    }

    else if (n == "10") {
      return "31";
    }

    else if (n == "11") {
      return "30";
    }
    else {


      return "31";

    }



  }

  private errorHandling(error: HttpErrorResponse) {
    //return Observable.throw(error.message || 'Something Went Wrong');
    return throwError(new Error(error.message));
  }

  request_am_services(str) {
    this.isload = true;
    var data = {
      "am_id": sessionStorage.getItem("account_manager_id"),
      'body': 'I want to learn more about this service',
      'client_id': sessionStorage.getItem("client_id"),
      'service': str
    };
    this.http.post(environment.baseUrlApi + "api/contact_am_gt.php", data, { responseType: 'json' }).subscribe(data => {
      //console.log(data['status']);
      if (data['status'] == 1) {
        // let service = '';
        // if(str == 'seo') {
        //   service = 'Search Engine Otimization';
        // }
        // else if(str == 'myPr') {
        //   service = 'myPractice Reputation';
        // }
        // else if(str == 'sm') {
        //   service = 'Social Media';
        // }
        // else if(str == 'mypl') {
        //   service = 'myPractice Listing';
        // }
        // else {
        //   service = str;
        // }
        // let jiraParams = {
        //   'title': `Enable ${service} for ${sessionStorage.getItem("display_name")}`,
        //   'description': `I want to know more ${str} service`,
        //   'client_id': sessionStorage.getItem("client_id"),
        //   'sf_id': sessionStorage.getItem("sf_id"),
        //   'website': sessionStorage.getItem("website"),
        //   'jira_user': sessionStorage.getItem("jira_user"),
        //   'auth_token': sessionStorage.getItem("auth_token"),
        //   'account_manager_name': sessionStorage.getItem("account_manage_name"),
        //   'epic_link': sessionStorage.getItem("epic_link")
        // }
        // this.postdata(jiraParams).subscribe(res => {

        // })

        if (str == "seo") {
          this.requestSeo = true;
        }
        else if (str == "myPr") {
          this.requestMypr = true;
        }
        else if (str == "mypl") {
          this.requestmypl = true;
        }
        else if (str == "sm") {
          this.requestSm = true;
        }
        else if (str == "web") {
          this.requestWeb = true;
        }
        else if (str == "pt") {
          this.requestPt = true;
        }
        this.isload = false;
      }
      else {
        this.isload = false;
      }

      //this.router.navigate(['/support']);
    })



    //console.log(str);
  }

  logout_auth() {

    sessionStorage.setItem("client_id", '');
    sessionStorage.setItem("profile_id", '');
    sessionStorage.setItem("username", '');
    sessionStorage.setItem("firstName", '');
    sessionStorage.setItem("lastName", '');
    sessionStorage.setItem("email", '');
    sessionStorage.setItem("phoneNo", '');
    sessionStorage.setItem("location", '');
    sessionStorage.setItem("account_manager_email", '');
    sessionStorage.setItem("account_manage_name", '');
    sessionStorage.setItem("account_manage_profile_image", '');
    sessionStorage.setItem("client_profile_image", '');
    sessionStorage.setItem("display_name", '');
    sessionStorage.setItem("am_mobile", '');
    sessionStorage.setItem("auth_token", '');
    sessionStorage.setItem("entity_type", '');
    sessionStorage.setItem("ga_view_id", '');
    sessionStorage.setItem("website", '');
    sessionStorage.setItem("twitter_id", '');
    sessionStorage.setItem("linkedIn_id", '');
    sessionStorage.setItem("fb_page_id", '');
    sessionStorage.setItem("jira_user", '');
    sessionStorage.setItem("is_seo", '');
    sessionStorage.setItem("is_newseo", '');  // new seo report
    sessionStorage.setItem("is_rm", '');
    sessionStorage.setItem("is_sm", '');
    sessionStorage.setItem("is_newsm", '');  // new social media 
    sessionStorage.setItem("sf_id", '');
    sessionStorage.setItem("seo_state", '');
    sessionStorage.setItem("state_longitude", '');
    sessionStorage.setItem("state_latitude", '');
    sessionStorage.setItem("mypr_access_key", '');
    sessionStorage.setItem("targeting_access_key", '');
    sessionStorage.setItem("from_am", "");

    sessionStorage.setItem("isLogedin", '');
    this.isUserLogedin = false;
    this.isload = false;
    this.router.navigate(['/']);
  }


  // Old SEO API start ---------------------------

  gmb_old(sdate, edate) {

    var emonth = edate.substr(5, 2);
    var eyear = edate.substr(0, 4);
    //console.log(eyear+"-"+emonth);
    var date = new Date();
    var stwoDigitMonth = ((date.getMonth() + 1) >= 10) ? (date.getMonth() + 1) : '0' + (date.getMonth() + 1);
    if (date.getFullYear() == eyear && stwoDigitMonth == emonth) {
      edate = date.getFullYear() + "-" + stwoDigitMonth + "-" + date.getDate();
    }

    var client_id = sessionStorage.getItem("client_id");
    var gmb_location = sessionStorage.getItem("gmb");
    var datas = { "client_id": client_id, "start_date": sdate, "end_date": edate, "gmb_location": gmb_location };
    if (sessionStorage.getItem("is_seo") != "1") {
      return this.http.get("assets/gmb.json")
        .pipe(catchError(this.errorHandling))
    }
    else {
      return this.http.post(environment.baseUrlApi + "api/gmb_data.php", datas, { responseType: 'json' })
        .pipe(catchError(this.errorHandling))
    }
  }

  g_analytic_old(sdate, edate) {

    //sdate="2019-01-01";
    //edate="2019-01-31";
    var client_id = sessionStorage.getItem("client_id");
    const body = new HttpParams()
      .set('view_id', sessionStorage.getItem("ga_view_id"))
      .set('start_date', sdate)
      .set("end_date", edate)
      .set('auth_token', sessionStorage.getItem("auth_token"))
      .set('client_id', sessionStorage.getItem("client_id"))
      .set('seo_state', sessionStorage.getItem("seo_state"));
    if (sessionStorage.getItem("is_seo") != "1") {
      return this.http.get("assets/seo-overview.json")
        .pipe(catchError(this.errorHandling))
    }
    else {
      //var payload= {"start_date":sdate,"end_date":edate,"client_id":client_id};
      return this.http.post(environment.baseUrl + "seo/report", body.toString(), { headers: headers })
        .pipe(catchError(this.errorHandling))
    }

  }

  // old SEO API end -------------------------------

  facebook(queryParams) {
    return this.http.get(environment.baseUrl + "sm/facebook" + "?" + queryParams);
  }

  linkedin_data(queryParams) {
    return this.http.get(environment.baseUrlApiBeanStalk+ "sm/linkedin_data" + "?" + queryParams);
  }
  // linkedin_data(queryParams) {
  //   return this.http.get(environment.baseUrl + "sm/linkedin_data" + "?" + queryParams);
  // }

  forgotPassword(params) {
    return this.http.post(environment.baseUrl + "clients/forgot_password", params);
  }

  resetPassword(params) {
    return this.http.post(environment.baseUrl + "clients/reset_password", params);
  }

  twitter_data(queryParams) {
    
    return this.http.get(environment.baseUrlApi + "api/twitter/tweets-countsAPI.php" + "?" + queryParams);
    //return this.http.get(environment.baseUrl + "sm/twitter_data" + "?" + queryParams);
  }

  twitter_dataForSM(queryParams) {
    return this.http.get(environment.baseUrlApi + "api/twitter/retweets-countsAPI.php" + "?" + queryParams);
  }

  imageUploadS3(image){
    return this.http.post(environment.baseUrl + "clients/S3ImageUpload", image, { responseType: 'json' })
      .pipe(catchError(this.errorHandling))
  }

  profileUpdate(data){
    return this.http.post(environment.baseUrl + "clients/updateProfile", data, { responseType: 'json' })
      .pipe(catchError(this.errorHandling))
  }
  pbformBlockedIp(data) {
    data.pb_client_id = sessionStorage.getItem("pb_client_id")
    data.client_id = sessionStorage.getItem("client_id");
    data.auth_token = sessionStorage.getItem("auth_token");
    return this.http.post(environment.baseUrl + "pbf/blockedIp", data, { responseType: 'json' })
      .pipe(catchError(this.errorHandling))
  }

  blockedList_ip(data) {
    data.pb_client_id = sessionStorage.getItem("pb_client_id")
    data.client_id = sessionStorage.getItem("client_id");
    data.auth_token = sessionStorage.getItem("auth_token");
    return this.http.post(environment.baseUrl + "pbf/blockedList_ip", data, { responseType: 'json' })
      .pipe(catchError(this.errorHandling))
  }

  deleteIp(data) {
    data.pb_client_id = sessionStorage.getItem("pb_client_id")
    data.client_id = sessionStorage.getItem("client_id");
    data.auth_token = sessionStorage.getItem("auth_token");
    return this.http.post(environment.baseUrl + "pbf/deleteIp", data, { responseType: 'json' })
      .pipe(catchError(this.errorHandling))
  }

  blockUnblockIp(data) {
    data.pb_client_id = sessionStorage.getItem("pb_client_id")
    data.client_id = sessionStorage.getItem("client_id");
    data.auth_token = sessionStorage.getItem("auth_token");
    return this.http.post(environment.baseUrl + "pbf/blockUnblockIp", data, { responseType: 'json' })
      .pipe(catchError(this.errorHandling))
  }

  deleteBlockIpSpam(data) {
    data.pb_client_id = sessionStorage.getItem("pb_client_id")
    data.client_id = sessionStorage.getItem("client_id");
    data.auth_token = sessionStorage.getItem("auth_token");
    return this.http.post(environment.baseUrl + "pbf/deleteBlockIpSpam", data, { responseType: 'json' })
      .pipe(catchError(this.errorHandling))
  }


}
